<template>
    <div class="bankCardPage">
        <div class="navbar">
            <div class="navbar_btn" @click="$router.go(-1)">
                <svg-icon iconClass="leftArrow"></svg-icon>
            </div>
            <div class="navbar_title">银行卡</div>
        </div>
        <div class="body">
            <div class="cardBox">
                <div class="cardNameBox">
                    <div class="name">{{bankCardInfo.name}}</div>
                    <div class="bankName">{{bankCardInfo.bankType}}</div>
                </div>
                <div class="bankCardNo">{{bankCardInfo.cardNum}}</div>
            </div>
            <div class="tip mt40">*只能绑定一张银行卡</div>
            <div class="tip mt20">*请确保银行卡信息正确，若错误会导致提现失败</div>
            <router-link tag="div" to="bindBankCard" class="modifyBtn">更换银行卡</router-link>
        </div>
    </div>
</template>

<script>
    import {
        queryBankCard,
    } from '@/api/user.js'
    export default {
        data() {
            return {
                bankCardInfo: {},
            }
        },
        created() {
            this.queryBankCard();
        },
        methods: {
            async queryBankCard() {
                let ret = await this.$Api(queryBankCard);
                if (ret && ret.code == 200) {
                    this.bankCardInfo = ret.data.bankInfo;
                }
            },
        }
    }
</script>

<style lang='scss' scoped>
    .bankCardPage {
        .navbar {
            background: $vermillion;
            color: $white1;
            height: 44px;
            font-size: 20px;
            padding: 0 16px;

            display: flex;
            align-items: center;

            .navbar_btn {
                margin-right: 28px;
            }

            .navbar_title {
                font-weight: 600;
            }
        }

        .body {
            padding: 16px 16px 0;

            .cardBox {
                padding: 10px 20px;
                height: 60px;
                border-radius: 4px;
                background: $vermillion;
                color: $white1;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .cardNameBox {
                    display: flex;
                    justify-content: space-between;
                    font-size: 12px;

                    .bankName {
                        color: rgba(255, 255, 255, 0.9);
                    }
                }

                .bankCardNo {
                    font-size: 16px;
                    font-weight: 600;
                }
            }
            .tip {
                font-size: 14px;
                color: $greyishBrown;
            }
            .modifyBtn {
                width: 100%;
                height: 42px;
                line-height: 42px;
                border-radius: 27px;
                font-size: 20px;
                margin-top: 35px;
                color: $white1;
                background: $vermillion;
                text-align: center;
            }
        }
        .mt40 {
            margin-top: 40px;
        }
        .mt20 {
            margin-top: 20px;
        }
    }
</style>